<template>
	<div class="sing">
		
		<vue-esign class="esgin_box" ref="esign" :width='375' :height="screenHeight" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor" :bgColor.sync="bgColor" />
		
    <div style="transform:rotate(90deg)">
      <div class="header_title">请在下方空白处使用正楷写您的名字</div>
    <div class="listBtn">
			<button class="bg1" style="margin-left:20px" @click="handleReset">清空面板</button>
			<button v-if="!imgWidth" @click="configBtn">查看协议</button>
			<button class="bg2" @click="cloneBtn" v-if="ladorshow!=true">关闭</button>
			<!-- <button v-if="!clone" @click="configBtn">查看协议</button> -->
      <button v-if="clone" @click="cloneBtns">关闭</button>
			<span  class="signName bg3" @click="handleGenerate">确认签名</span>
		</div>
    </div>
    
	</div>
</template>
<script>

import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      bgColor: "",
      isCrop: "",
      lineWidth: 5,
      lineColor: "",
      resultImg: "",
      imgConfig: "{ useCdnDomain: true }",
      imgUrl:'',
      screenHeight:''
    };
  },
  props: {
    imgToken: {
      type: String
    },
    imgWidth:{ type:Number},
    clone:{
      type: Boolean
    },
    ladorshow:{ type:Boolean}
  },
  // created() {},
  // methods: {
   
  created() {
    this.screenHeight = document.documentElement.clientHeight;
  },
  methods: {
     cloneBtn(){
        this.$emit("cloneBtn");
    
  },
    cloneBtns(){
      this.$emit("cloneBtns")
    },
    handleReset() {
      this.$refs.esign.reset();
      this.$emit("cloneImg")
    },
    handleGenerate() {
      console.log(this.config, "222222");
      this.$refs.esign
        .generate()
        .then(res => {
          console.log(res, "res");
          // this.resultImg = res;
          this.fildsData(res,"file");
        })
        .catch(err => {console.log(err, "err");
          this.$emit("signatureBtn")
        });
    },
    async pdfUgblods(pageData) {
      console.log(pageData, "e131");

      let fd = new FormData();
      fd.append("token", this.imgToken);
      fd.append("config", this.imgConfig);
      fd.append("file", pageData);
      console.log(fd, "fd");
      let imgRes = await apiCommon.uploadImg(fd);
      console.log(imgRes);
      this.imgUrl = 'https://cdn.health.healthplatform.xyz/' + imgRes.key;
      // return;
      if(this.clone){
        this.$emit("imgUblod",this.imgUrl,1)
      }else{
        this.$emit("imgUblod",this.imgUrl,0)
      }
      
      // Cookies.set("imgName", this.imgUrl);
    },
    fildsData(dataurl, filename) {
      //将base64转换为文件
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      console.log(u8arr,"u8arr");
      this.pdfUgblods(new File([u8arr], filename, {
    type: mime
  }))
     
    },
    configBtn() {
      // this.$router.push("./agreement")
      this.$router.go(-1)
    }
  }
};
</script>
<style type="">
.esgin_box{
  position: relative;
  /* z-index: 9; */
}
.sing{
  width:100%;height:100%;
  position: relative;
}
.sing .header_title {
  width: 490px;
  height: 107px;
  /* background: #f3f2f7; */
  opacity: 1;
  border-radius: 20px 20px 0px 0px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 107px;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  font-size: 30px;
  /* margin-bottom:350px */
  position: absolute;
  top: -320px;
  right: 120%;
}
button {
  background-color: #fff;
  border: 2px solid #000;
  width: 160px;
  height: 72px;
  line-height: 72px;
  text-align: center;
  font-size: 30px;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 9px;
  margin-right: 20px;
}
.listBtn {
  display: flex;
  /* width: ; */
  /* border-top: 2px solid rgba(112, 112, 112, 0.5019607843137255); */
  padding-top: 20px;
  position: absolute;
  right: 800px;
  bottom: -300px;
  z-index: 9999
}
.signName {
  width: 160px;
  height: 72px;
  background: #fc3757;
  opacity: 1;
  border-radius: 9px;
  text-align: center;
  line-height: 72px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
  margin-left: 50px;
  
}
</style>